import Vue from 'vue/dist/vue.esm.js';
import '../../css/public/engraving.css'

Vue.component('add-to-cart', {
    template: `#add-to-cart`,
    delimiters: ['[[', ']]'],
    props: {
        addToCartUrl: {
            required: true,
            type: String,
        },
        product: {
            required: true,
            type: Object,
        },
        locale: {
            required: true,
            type: String,
        },
        currency: {
            required: true,
            type: String,
        },
        engravingPrice: {
            required: true,
            type: String,
        },
    },

    mounted() {

        this.buildAttributes();

        if (this.product.variants.length === 1) {
            this.selectedVariant = this.defaultVariant;
            for (let attribute of this.attributes) {
                for (let option of attribute.availableOptions) {
                    option.selected = true;
                }
            }
        } else {
            for (let single_variant of this.product.variants) {
                if (single_variant.quantity > 0) {
                    this.selectedVariant = single_variant;
                    break;
                }
            }
        }
        if (this.selectedVariant && this.selectedVariant.options && this.selectedVariant.options.length > 0) {
            this.selectedVariant.options[0].value.selected = true;
            this.refreshAvailableAttributeOptions();
            this.checkUnselectedAttributes()
            this.updateSku();
            this.updateGallery();
        }

        if (this.product.hasEngraving) {
            this.initSelection();
            this.setupConfig();
        }

        fbq('track', 'ViewContent', {
            content_type: 'product',
            content_ids: [this.product.id]
        });
    },

    data() {
        return {
            selectedOptions: [],
            defaultVariant: this.product.defaultVariant,
            selectedVariant: null,
            variants: this.product.variants,
            attributes: [],
            gallery: this.product.gallery,
            availableVariants: this.product.variants,
            quantity: 1,
            maxQuantityReached: false,
            variantsCount: this.product.variants.length,
            availableForAdd: true,
            engraving: false,
            engravingPaddingStyle: null,
            engravingPaddingMaxWidth: null,
            engravingPaddingMaxHeight: null,
            modelFields: {}
        }
    },

    watch: {
        modelFields: {
            handler(newVal, oldVal) {
                this.$nextTick(() => {
                    this.handleEngravingFieldChange();
                });
            },
            deep: true, // Set to true if you want to watch nested properties
        },
    },

    methods: {
        buildAttributes() {

            let _self = this;
            this.variants.map(function (variant) {
                let selected = variant.options.length === 1;

                variant.options.map(function (option) {
                    let attributeData = _self.attributes.filter(a => a.slug === option.attribute.slug)[0];

                    if (attributeData === undefined) {
                        attributeData = {
                            availableOptions: [],
                            name: option.attribute.name,
                            slug: option.attribute.slug,
                            showUnit: option.attribute.showUnit,
                            image: option.attribute.image,
                            isColor: option.attribute.isColor,
                            selected: true,
                            selectedValue: null
                        };

                        _self.attributes.push(attributeData);
                    }

                    option.value.unit = variant.unit;
                    option.value.unitDiscount = variant.unitDiscount;
                    option.value.price = variant.price;
                    option.value.quantity = variant.quantity;
                    if (attributeData.availableOptions.filter(v => v.id === option.value.id && v.unit === option.value.unit).length === 0) {

                        //option.value.selected = selected;
                        attributeData.availableOptions.push(option.value);
                    }
                })
            });

        },
        selectAttribute(option, attribute) {
            let _self = this;
            option.selected = !option.selected;

            if (option.selected) {
                attribute.selectedValue = option.label;
            } else {
                attribute.selectedValue = null;
            }
            attribute.availableOptions.map(function (o) {
                if ((o.id !== option.id) || (o.id === option.id && o.unit !== option.unit)) {
                    o.selected = false;
                    _self.selectedOptions = _self.selectedOptions.filter(op => op.selected);
                }
            });

            if (option.selected) {
                _self.selectedOptions.push(option);
            }


            _self.availableVariants = [];
            this.variants.map(function (variant) {
                if (_self.variantHasAllAttributeValues(variant, _self.selectedOptions)) {
                    _self.availableVariants.push(variant);
                }
            });

            if (this.availableVariants.length === 1) {
                this.selectedVariant = this.availableVariants[0];
                this.maxQuantityReached = false;
                this.fixSelectedQuantity();
            } else if (this.availableVariants.length === 0) {
                this.resetAllExcept(option);
                this.selectedVariant = null;
            } else {
                this.selectedVariant = this.availableVariants[0];
            }
            this.refreshAvailableAttributeOptions();
            this.checkUnselectedAttributes();
            this.updateSku();
            this.updateGallery();
            this.checkStockStatus();

        },

        resetAllExcept(lastOption) {
            for (let selectedOption of this.selectedOptions) {
                if (!this.matchOption(selectedOption, lastOption)) {
                    // selectedOption.selected = false;
                }
            }

            this.selectedOptions = [lastOption];

        },
        updateSku() {
            let sku = this.selectedVariant ? this.selectedVariant.sku : this.product.sku;
            $('#product-sku').text(sku);
        },
        updateGallery() {
            let gallery = this.selectedVariant ? this.selectedVariant.gallery : this.product.gallery;
            if (this.gallery != gallery) {
                this.gallery = gallery;
                EventManager.fire('gallery-changed', this.gallery);
            }

        },
        checkStockStatus() {
            let isAvaiableCombination = true;
            for (let key in this.selectedOptions) {
                let option = (this.selectedOptions[key]);
                if (!option.available) {
                    isAvaiableCombination = false;
                }
            }
            if (this.attributes.length == 1) {
                isAvaiableCombination = this.selectedOptions[0].quantity > 0;
            }
            if (this.selectedOptions.length == 0) {
                document.querySelector('.product-stock.in-stock').style.display = 'none';
                document.querySelector('.product-stock.not-in-stock').style.display = 'none';
                this.availableForAdd = true;
            } else if (isAvaiableCombination) {
                document.querySelector('.product-stock.in-stock').style.display = 'block';
                document.querySelector('.product-stock.not-in-stock').style.display = 'none';
                this.availableForAdd = true;
            } else {
                document.querySelector('.product-stock.in-stock').style.display = 'none';
                document.querySelector('.product-stock.not-in-stock').style.display = 'block';
                this.availableForAdd = false;
            }
        },

        refreshAvailableAttributeOptions() {

            for (let attribute of this.attributes) {
                for (let option of attribute.availableOptions) {
                    option.available = this.isOptionAvailable(attribute, option);
                }
            }
        },

        isOptionAvailable(attribute, option) {
            if (this.attributes.length == 1) {
                return true;
            }
            let remainingVariants = this.variants.filter(v => this.variantHasAttributeValue(v, option));

            for (let eachAttribute of this.attributes) {
                if (eachAttribute === attribute) {
                    continue;
                }

                for (let selectedOption of eachAttribute.availableOptions.filter(o => o.selected)) {
                    remainingVariants = remainingVariants.filter(v => this.variantHasAttributeValue(v, selectedOption));
                }
            }

            return remainingVariants.length > 0;
        },

        variantHasAllAttributeValues(variant, selectedOptions) {
            for (let selectedOption of selectedOptions) {
                if (!this.variantHasAttributeValue(variant, selectedOption)) {
                    return false;
                }
            }

            return true;
        },

        variantHasAttributeValue(variant, selectedOption) {
            for (let option of variant.options) {

                if (option.value.id === selectedOption.id && option.value.unit === selectedOption.unit && option.value.quantity > 0) {
                    return true;
                }
            }
            return false;
        },

        matchOption(a, b) {
            return a.id === b.id && a.unit === b.unit;
        },

        addToCart() {
            if (!this.selectedVariant) {
                this.checkUnselectedAttributes();
                return;
            }
            let container = this.$refs.personalisationFormContainer;

            let formData = [];

            if (container) {
                let personalisationForm = container.querySelector('form');

                if (this.engraving && !personalisationForm.checkValidity()) {
                    personalisationForm.reportValidity();
                    return;
                }

                let submitUrl = personalisationForm.dataset.submitUrl,
                    formData = new FormData(personalisationForm)

                let _self = this;
                $.ajax({
                    url: submitUrl,
                    data: formData,
                    method: 'POST',
                    processData: false,
                    contentType: false,
                    success: function (response) {
                        if (response.success) {
                            container.innerHTML = response.content;
                            _self.sendAddToCartRequest(formData);
                        } else {
                            container.innerHTML = response.content;
                        }
                    },
                    error: function (error) {
                        console.log(error);
                    }
                });

            } else {
                this.sendAddToCartRequest(formData);
            }


        },

        sendAddToCartRequest(formData) {

            let additionalData = {};
            if (formData instanceof FormData) {
                formData.forEach(function (value, index) {
                    if (!index.includes("_token"))
                        additionalData[index] = value;
                })
            }

            let _self = this;
            $.ajax({
                data: {id: _self.selectedVariant.id, quantity: _self.quantity, additionalData: JSON.stringify(additionalData), engraving: _self.engraving},
                url: _self.addToCartUrl,
                method: 'POST',
                success: function (result) {
                    _self.successAjax(result)
                },
            });
        },

        checkUnselectedAttributes() {
            for (let attribute of this.attributes) {
                if (attribute.availableOptions.filter(a => a.selected === true).length === 0) {
                    attribute.selected = false;
                } else {
                    attribute.selected = true;
                }
            }
        },
        fixSelectedQuantity() {
            if (this.quantity > this.selectedVariantQuantity) {
                this.quantity = this.selectedVariantQuantity;
            } else if (this.quantity == 0 && this.selectedVariantQuantity > 0) {
                this.quantity = 1
            }
        },

        isPacked() {
            let variant = this.selectedVariant ?? this.defaultVariant;
            return variant.priceFactor !== 1;
        },

        incrementQuantity() {
            let maxQuantity = this.selectedVariantQuantity ?? 99;
            if (this.quantity > maxQuantity) {
                return;
            }
            this.quantity = parseInt(this.quantity) + 1;
        },

        decrementQuantity() {
            if (this.quantity === 1) {
                return;
            }
            if (this.quantity === 0) {
                return;
            }
            this.quantity--;
        },

        formattedPrice(price) {
            return new Intl.NumberFormat(this.locale, {style: 'currency', currency: this.currency}).format(price);
        },

        validatePersonalisationForm() {

            let form = this.$refs.personalisationForm;

            if (!form.checkValidity()) {
                form.reportValidity();
                return;
            }

            const inputs = form.querySelectorAll('input[type=text]');
            let allEmpty = true;

            inputs.forEach(function (input) {
                if (input.value.trim() !== '') {
                    allEmpty = false;
                }
            });

            let firstInput = inputs[0];
            if (allEmpty) {
                firstInput.setAttribute('required', '');
                firstInput.reportValidity();
            } else {
                if (!form.checkValidity()) {
                    form.reportValidity();
                }
                firstInput.removeAttribute('required');
                this.engraving = true;
                $('#add-engraving').modal('hide');

            }
        },
        clearPersonalisationForm() {
            let form = this.$refs.personalisationForm;
            const inputs = form.querySelectorAll('input[type=text]');
            inputs.forEach(function (input) {
                input.value = '';
            });

            this.engraving = false;
        },

        initSelection() {
            let form = this.$refs.personalisationForm;
            const selects = form.querySelectorAll('select');

            selects.forEach(select => {
                select.addEventListener('change', () => {
                    const allDataSlugs = Array.from(select.querySelectorAll('option[data-slug]'))
                        .map(option => option.getAttribute('data-slug'));

                    this.removeClassesFromInputs(allDataSlugs, form);
                    this.removeClassesFromLines(allDataSlugs);

                    const selectedOption = select.querySelector('option:checked');
                    const selectedDataSlug = selectedOption.getAttribute('data-slug');

                    this.addClassToInputs(selectedDataSlug, form);
                    this.addClassToLines(selectedDataSlug);
                });
            });
        },


        setupConfig() {
            let wrapper = this.$refs.engravingWrapper;
            let config = wrapper.getAttribute('data-config');
            let parsedValue = config.split(',').map(Number);
            const [top, right, bottom, left] = parsedValue;
            this.engravingPaddingStyle = top/2 + '% ' + 0 + '% ' + 0 + '% ' + left + '% ';
            this.engravingPaddingMaxWidth = (100 - right) + '%';
            this.engravingPaddingMaxHeight = (100 - bottom) + '%';
            let multiplier = parsedValue[parsedValue.length - 1];
            this.multiplier = multiplier;
            wrapper.style.setProperty('--font-multiplier', multiplier.toString());
        },

        removeClassesFromInputs(classNames, form) {
            const inputs = form.querySelectorAll('input');
            inputs.forEach(input => {
                classNames.forEach(className => {
                    if (input.classList.contains(className)) {
                        input.classList.remove(className);
                    }
                });
            });
        },
        removeClassesFromLines(classNames) {
            let container = this.$refs.engravingContainer;

            classNames.forEach(className => {
                if (container.classList.contains(className)) {
                    container.classList.remove(className);
                }
            });

        },

        addClassToInputs(className, form) {
            if (className) {
                const inputs = form.querySelectorAll('input');
                inputs.forEach(input => {
                    input.classList.add(className);
                });
            }
        },
        addClassToLines(className) {
            if (className) {
                let container = this.$refs.engravingContainer;
                container.classList.add(className);

            }
        },

        handleEngravingFieldChange() {
            const container = this.$refs.engravingWrapper;
            let fontSize = this.multiplier; // Start with a high font size

            container.style.setProperty('--font-multiplier', fontSize.toString());

            while (container.scrollWidth > container.offsetWidth && fontSize > 1) {
                fontSize = fontSize - 0.2;
                container.style.setProperty('--font-multiplier', fontSize.toString());
            }

            this.handleLineHeightOfEngravingField();
        },

        handleLineHeightOfEngravingField(){

            const container = this.$refs.engravingWrapper;
            let style = window.getComputedStyle(container);

            let count = Object.entries(this.modelFields).filter(([key, value]) => value != '').length;
            let internalHeightExcludingPadding = container.clientHeight -
                parseFloat(style.paddingTop) -
                parseFloat(style.paddingBottom);

            container.style.setProperty('line-height', internalHeightExcludingPadding/count + 'px');
        },

        successAjax(result) {

            this.maxQuantityReached = false;

            let successMessage = $('#success');
            let failMessage = $('#fail');

            if (result.success === false) {
                this.maxQuantityReached = true;
                return;
            } else {
                successMessage.show();
                failMessage.hide();
            }

            let modal = $('#checkout-modal');
            modal.modal('show');
            EventManager.fire('item-added', result.cart);

            let event = {
                event: 'eec.add',
                eventData: result.variant,
                action: 'ProductPage',
            }

            fbq('track', 'AddToCart', {
                content_ids: [result.variant.id],
                content_type: 'product',
                value: result.variant.priceInEUR,
                currency: 'EUR'
            });

            gtag('event', 'add_to_cart', {
                "items": [
                    {
                        "id": result.variant.id,
                        "name": result.variant.name,
                        "list_name": "Product view",
                        "quantity": 1,
                        "price": result.variant.priceInEUR
                    }
                ]
            });

            EventManager.fire('gtm-event', event);

        }

    },

    computed: {
        selectedVariantPrice() {
            if (this.selectedVariant) {
                return this.formattedPrice(this.selectedVariant.price);
            } else {
                return this.formattedPrice(this.defaultVariant.price);
            }
        },
        selectedVariantDiscountedPrice() {
            let variant = this.selectedVariant ?? this.defaultVariant;

            if (variant.discountedPrice !== false) {
                return this.formattedPrice(variant.discountedPrice);
            } else {
                return false;
            }
        },
        selectedVariantRegularPrice() {
            let variant = this.selectedVariant ?? this.defaultVariant;

            if (variant.regularPrice) {
                return this.formattedPrice(variant.regularPrice);
            } else {
                return false;
            }
        },
        selectedVariantPricePerMeasure() {
            let variant = this.selectedVariant ?? this.defaultVariant;

            return this.formattedPrice(variant.pricePerMeasure);

        },
        selectedVariantPriceFactor() {
            let variant = this.selectedVariant ?? this.defaultVariant;
            return variant.priceFactor;
        },
        selectedVariantMeasure() {
            let variant = this.selectedVariant ?? this.defaultVariant;
            return variant.measure;
        },
        modelFieldsCount() {
            return Object.entries(this.modelFields).filter(([key, value]) => value != '').length;
        },
        selectedVariantQuantity() {
            let variant = this.selectedVariant ?? this.defaultVariant;
            return variant.quantity;
        }
    }
});